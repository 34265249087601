<script>
    import Language from "./Language.svelte";
    import Logo from "./Logo.svelte";
    import { get } from "lodash-es";

    let lang = "en";
    export let property = null;
</script>
<article class="page handout instructions {property.format}" lang="{lang}">
    <Language bind:value={lang} />
    {#if "en" === lang}
        <header>
            <Logo property={property} />
            <h1>How to get an Amenity Pass</h1>
        </header>
        <h2>STEP 1:</h2>
        <p>Go to amenitypass.com on your smartphone, tablet, or computer. Search for "{property.title || property.name}" and tap/click on the result in the list.</p>
        <h2>STEP 2:</h2>
        <p>Tap/click on the amenity you want to use, select any available time for the pass, and tap/click "CONTINUE" at the bottom of the screen.</p>
        <h2>STEP 3:</h2>
        <p>Enter your {get(property, "tenants.format", "unit")} #, passcode, and name. You may also need to enter your phone number and/or email (this info will not be shared with outside parties). When everything has been entered, tap/click "GET PASS" at the bottom of the screen.</p>
        <h2>To cancel your pass:</h2>
        <p>Tap/click the "cancel pass" link at the bottom of your pass confirmation, enter your passcode, and tap/click "CANCEL PASS". Passes may not be canceled after the start time.</p>
        <h2>To get a pass for multiple people:</h2>
        <p>Repeat the process above for each person who needs to use the amenity. Every person using a shared amenity (one not reserved by a single {get(property, "tenants.format")}) MUST have their own pass. Limits may apply according to the amenity rules.</p>
    {:else if "es" === lang}
        <header>
            <Logo property={property} />
            <h1>Cómo obtener un Amenity Pass</h1>
        </header>
        <h2>PASO 1:</h2>
        <p>Vaya a amenitypass.com en su teléfono inteligente, tableta u ordenador. Busque "{property.title || property.name}" y pulse/haga clic en el resultado en la lista.</p>
        <h2>PASO 2:</h2>
        <p>Pulse/haga clic en la amenidad que desea utilizar, seleccione cualquier tiempo disponible para el pase y pulse/haga clic en "CONTINUE" en la parte inferior de la pantalla.</p>
        <h2>PASO 3:</h2>
        <p>Ingrese su apartamento, código de acceso y nombre. Es posible que también deba introducir su número de teléfono y/o correo electrónico (esta información no se compartirá con terceros). Cuando se haya introducido todo, pulse/haga clic en "GET PASS" en la parte inferior de la pantalla.</p>
        <h2>Para cancelar tu pase:</h2>
        <p>Pulse/haga clic en el enlace "cancel pass" en la parte inferior de la confirmación de su pase, introduzca su código de acceso y pulse/haga clic en "CANCEL PASS". Los pases no se pueden cancelar después de la hora de inicio.</p>
        <h2>Para obtener un pase para varias personas:</h2>
        <p>Repita el proceso anterior para cada persona que necesite usar el amenidad. Cada persona que utilice un servicio compartido (uno no reservado por un solo apartamento) DEBE tener su propio pase. Los límites pueden aplicarse de acuerdo con las reglas de servicios.</p>
    {/if}
</article>