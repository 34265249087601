<script>
  import QR from "./QR.svelte";
  import { get, capitalize } from "lodash-es";
  import Language from "./Language.svelte";
  import Logo from "./Logo.svelte";
  import Tenant from "./Tenant.svelte";

  export let property = null;
  export let policy = null;
  export let tenant = null;

  let lang = "en";

  $: title = [get(property, "name", ""), get(policy, "title", "")]
    .filter((item) => !!item)
    .join(" - ");
</script>

<article class="page handout {property.format}" {lang}>
  <Language bind:value={lang} />

  {#if "en" === lang}
    <header>
      <Logo {property} />
      <h1>Welcome to Amenity Pass for {property.name}</h1>
    </header>
    <p>
      Our amenities are a valuable resource to be enjoyed by all of our
      residents. Amenity Pass allows us to manage amenities in a responsible and
      fair way so all residents have an equal opportunity to use them. An
      electronic pass is now required for everyone using an amenity.
    </p>

    <QR {property} {policy} {lang} format={"handout"} />
    <Tenant {tenant} {lang} />
    <footer>
      <p>
        Thank you for your cooperation and helping us keep our community a great
        place to live.
      </p>
      <p>— {get(property, "name") || "Management Office"}</p>
    </footer>
  {:else if "es" === lang}
    <header>
      <Logo {property} />
      <h1>Bienvenido a Amenity Pass para {property.name}</h1>
    </header>

    <p>
      Nuestras comodidades son un recurso valioso para que disfruten todos
      nuestros residentes. Amenity Pass nos permite administrar los servicios de
      manera responsable y justa para que todos los residentes tengan la misma
      oportunidad de usarlos. Ahora se requiere un pase electrónico para todos
      los que usan un servicio.
    </p>

    <QR {property} {policy} {lang} format={"handout"} />
    <Tenant {tenant} {lang} />

    <footer>
      <p>
        Gracias por su cooperación y por ayudarnos a mantener nuestra comunidad
        como un excelente lugar para vivir.
      </p>
      <p>— {get(property, "name") || "Management Office"}</p>
    </footer>
  {/if}
</article>
