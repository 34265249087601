import { writable, derived } from "svelte/store";
import { items } from '@parkingboss/svelte-utils'
import { merge, get, map, sortBy } from "lodash-es";
import { fetchAndStorePropertyPolicies, fetchAndStoreUnits, fetchAndStoreSpaces, resolveProperty } from "./api";

const comparer =  !!window.Intl && !!window.Intl.Collator ? new Intl.Collator(undefined, { numeric: true, sensitivity: 'base', caseFirst: 'lower' }).compare : (a, b) => a < b ? -1 : a > b ? 1 : 0;

//export const state = writable({});

export const state = items;

export const tenantId = writable(null);
export const spaceId = writable(null);

export const params = writable({});
export const permitId = derived([ params ], ([ params ]) => params.permit && params.permit.toLowerCase());
export const policyId = derived([ params ], ([ params ]) => params.policy && params.policy.toLowerCase());
export const propertyId = derived([ params ], ([ params ]) => params.property && params.property.toLowerCase());
export const view = derived([ params ], ([ params ]) => params.view);
export const valid = derived([ params ], ([ params ]) => params.valid);
export const search = derived([ params ], ([ params ]) => params.q || params.search);


export const propertyIds = writable(JSON.parse(localStorage.getItem("properties") || "{}"));
// write to backing store
propertyIds.subscribe(value => {
    //"writing properties to local storage", value);
    localStorage.setItem("properties", JSON.stringify(value));
});

// refresh permits on property change

async function updateProperty(property) {

    fetchAndStorePropertyPolicies(property);
    fetchAndStoreUnits(property);
    fetchAndStoreSpaces(property);

}

// once you're on a property, assumed until the actual value changes
// store previous value and use value equality to prevent frothy requests
//let permitsRefresher = null;
let propertyRefresher = null;
let previousPropertyId = null;
propertyId.subscribe(async value => {
    //if(!!propertyRefresher) clearInterval(propertyRefresher); // alway stop the scheduler

    if(!value) return; // don't do anything, but keep previous value cached - leave refreshers active

    if(value === previousPropertyId) return; // the assignment changed, but not the actual value;

    // we have a new ID
    if(!!propertyRefresher) clearInterval(propertyRefresher); // stop the previous scheduler
    //if(!!permitsRefresher) clearInterval(permitsRefresher); // stop the previous scheduler

    previousPropertyId = value;

    //console.log("propertyId changed=", value);

    
    updateProperty(value);
    propertyRefresher = setInterval(() => updateProperty(value), 10 * 60 * 1000);

   
});

export const property = derived([ propertyId, state ], ([ id, items ]) => resolveProperty(items[id], items));

export const policies = derived([ property, state ], ([ property, state ]) => {
    if(!property) return null;
    if(!state["policies"]) return null;
    var policies = map(state["policies"], (version, policy) => state[policy] || state[version]);
    //console.log("policies=", policies);
    if(!policies.every(item => !!item)) return null; // not done loading

    return policies
    .filter(item => !!item && item.scope === property.id && (item.amenity === "yes" || item.amenity === "amenity")).map(item => {
        // item.statistics = get(state, [ "statistics", item.id ]) || get(state, [ "statistics", item.subject ]);
        // item.meters.items = sortBy(map(item.meters.items, m => {
        //     m = state[m] || m;
        //     if(!m) return m;
        //     if(m.group === "tenant") m.group = get(property, "tenants.format", m.group);
        //     return m;
        // }), [ "rank" ]);
        item.property = resolveProperty(item.location, state);
        return item;
    }).sort((a, b) => comparer(a.title, b.title));
});

export const policy = derived([ policyId, policies, state ], ([ id, policies, items ]) => {
    var item = !!id && !!policies && policies.find(item => item.id === id || item.subject === id);
    return item;
    return merge(item, {
        property: resolveProperty(item.location, items),
    });
});

export const permits = derived([ property, state ], ([ property, items ]) => {

    if(!property) return null;

    if(!property) return null;
    if(!items["permits"]) return null;
    var values = map(items["permits"], (value, key) => items[key]);
    //console.log("permits=", values);
    if(!values.every(item => !!item)) return null; // not done loading

    //if(!every(values, i => !!i)) return null;

    return values.filter(permit => permit && (!permit.amenity || permit.amenity === "parking")).map(permit => merge(permit, {
        property: resolveProperty(items[permit.location] || permit.location, items),
        address: items[permit.address] || permit.address,
        policy: items[permit.issued.issuer] || permit.issued.issuer,
        vehicle: items[permit.vehicle] || permit.vehicle,
        spaces: (permit.spaces || []).map(i => items[i] || i),
        tenant: items[permit.tenant] || permit.tenant,
        media: permit.media && (items[permit.media] || permit.media),
    }));
    
    // check for missing?


    return values;

});

export const properties = derived([ propertyIds, state ], ([ ids, items ]) => {

    if(!ids) return null;
    //console.log("properties=", ids);
    const values = map(ids, (timestamp, id) => resolveProperty(items[id], items));
    //console.log("properties=", values);
    if(!values.every(item => !!item)) return null; // not done loading
    
    //console.log("properties=", values);
    //if(!every(values, i => !!i)) return null;
    
    // check for missing?


    return values;

});

export const units = derived([ property, state ], ([ property, state ]) => {
    if(!property) return null;
    return map(get(state, "units.items"), (value, key) => state[key]).filter(item => !!item && item.scope === property.id).sort((a, b) => comparer(a.display, b.display));
});

export const tenants = derived([ property, state ], ([ property, state ]) => {
    if(!property) return null;
    return map(get(state, "tenants.items"), (value, key) => state[key]).map(item => {
        if(!item) return item;
        item.authcode = get(state, [ "authcodes", "items", item.id ], item.authcode);
        //console.log("tenant=", item);
        return item;
    }).filter(item => !!item && item.scope === property.id).sort((a, b) => comparer(a.display, b.display));
});

export const spaces = derived([ property, state ], ([ property, state ]) => {
    if(!property) return null;
    if(!get(state, "spaces.items")) return null;
    return map(get(state, "spaces.items"), (value, key) => state[key]).map(item => {
        if(!item) return item;
        //console.log("tenant=", item);
        return item;
    }).filter(item => !!item && item.scope === property.id).sort((a, b) => comparer(a.display, b.display));

});

export const space = derived([ spaceId, state ], ([ id, items ]) => items[id]);
export const tenant = derived([ tenantId, state ], ([ id, items ]) => items[id]);

// on permit update selected property
policy.subscribe(policy => {

    if(!policy) return;
    params.update(prev => merge(prev, {
        property: policy.property.id || policy.property || policy.location,
    }));
});


// loggers
//permit.subscribe(value => console.log("permit.store=", value));
//properties.subscribe(value => console.log("properties.store=", value));
// permits.subscribe(value => console.log("permits.store=", value));
// view.subscribe(value => console.log("view.store=", value));
//policy.subscribe(value => console.log("policy.store=", value));
// policies.subscribe(value => console.log("policies.store=", value));
//units.subscribe(value => console.log("units.store=", value));