<script>

    import { upperFirst } from "lodash-es";

    export let lang = "en";
    export let tenant = null;

</script>

{#if tenant && tenant.authcode}
    <dl>
        {#if "es" === lang}
            <dt>{upperFirst(tenant.format)}:</dt><dd>{tenant.display}</dd>
            <dt>Contraseña:</dt><dd>{tenant.authcode}</dd>
        {:else}
            <dt>Your {tenant.format}:</dt><dd>{tenant.display}</dd>
            <dt>Your passcode:</dt><dd>{tenant.authcode}</dd>
        {/if}
    </dl>
{/if}