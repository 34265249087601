<script>

    export let value = "en";

</script>
<nav>
    <select value={value} on:change={e => (value = e.target.value)} on:blur={e => (value = e.target.value)}>
        <option value="en">English</option>
        <option value="es">Spanish</option>
    </select>
</nav>