import { Api } from '@parkingboss/api';
import { each, merge, get, debounce, has } from "lodash-es";
import { addHours } from "date-fns";
import { updateItems } from '@parkingboss/svelte-utils'

export const api = new Api({
    client: "AmenityNotice",
    //apiBase: document.querySelector("link[rel~='api'][rel~='index'][type='application/json']").href
});


let authHeader = "";
api.user.subscribe(auth => auth && (authHeader = `Authorization=${auth.type} ${auth.token}`));

export async function fetchUnits(scope, sent) {
    if(!scope) return null;
    const json = await Promise.all([
        fetch(`${api.settings.apiBase}/units/tenants?scope=${scope}&authcodes=true&sent=${sent === true}&viewpoint=${new Date().toISOString()}&valid=${encodeURIComponent(new Date().toISOString() + "/" + new Date().toISOString())}&${authHeader}`),
    ])
    .then(values => Promise.all(values.map(res => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then(values => merge({}, ...values));

    return json;

    //state.update(prev => merge(prev, json.items));
}

export async function fetchSpaces(scope) {
    if(!scope) return null;
    const json = await Promise.all([
        fetch(`${api.settings.apiBase}/spaces?scope=${scope}&viewpoint=${new Date().toISOString()}&${authHeader}`),
    ])
    .then(values => Promise.all(values.map(res => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then(values => merge({}, ...values));

    return json;

    //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreSpaces(scope) {
    const json = await fetchSpaces(scope);

    each(json, (value, key) => {
        //console.log(key, value);
        if(has(value, "items")) json.items[key] = value; //.items;
    });

    updateItems(json);

    //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreUnits(scope) {
    const json = await fetchUnits(scope);

    each(json, (value, key) => {
        //console.log(key, value);
        if(has(value, "items")) json.items[key] = value; //.items;
    });

    updateItems(json);

    //state.update(prev => merge(prev, json.items));
}

export const fetchAndStorePropertyPolicies = debounce(async function(property) {
    const json = await Promise.all([
        fetch(`${api.settings.apiBase}/properties?viewpoint=${new Date().toISOString()}&property=${property}`),
        fetch(`${api.settings.apiBase}/permits/policies/issue?scope=${property}&viewpoint=${new Date().toISOString()}&public=true`),
        //fetch(`${api.settings.apiBase}/permits?viewpoint=${new Date().toISOString()}&vehicle=true&scope=${property}&valid=${addHours(new Date(), -2).toISOString()}/&${authHeader}`),
        //fetch(`${api.settings.apiBase}/spaces?scope=${id}&viewpoint=${new Date().toISOString()}`)
    ])
    .then(values => Promise.all(values.map(res => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then(values => merge({}, ...values));

    each(json, (value, key) => {
        //console.log(key, value);
        if(has(value, "items")) json.items[key] = value.items;
    });

    updateItems(json);
}, 1000, {
    trailing:true,
    leading:true,
});


async function responseJson(response) {
    if(!response) return {};
    return response.text()
    .then(function(text) {

        if(!text) return {
            status: response.status
        };

        return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function(error) {
            return {
                status: response.status,
                message: text
            };
        });

    })
    .catch(function(error) {
        return {
            status: response.status
        };
    });
}

export function resolvePermits(values, items) {

    // values is the list of permits, items is the overall state

    if(!values || !items) return null;
    values = map(values, (value, key) => items[key] || item[value] || value || key);
    if(!values.every(item => !!item && !!item.id)) return null; // not done loading

    return values.filter(permit => permit && permit.amenity !== "parking").map(permit => !permit ? permit : merge(permit, {
        property: resolveProperty(items[permit.location] || permit.location, items),
        address: items[permit.address] || permit.address,
        policy: items[permit.issued.policy] || items[permit.issued.issuer] || permit.issued.issuer,
        vehicle: items[permit.vehicle] || permit.vehicle,
        spaces: (permit.spaces || []).map(i => items[i] || i),
        tenant: items[permit.tenant] || permit.tenant,
        contact: get(items, [ "contacts", "items", permit.id ], permit.contact),
        notes: get(items, [ "notes", "items", permit.id ], permit.notes),
    }));

}

export function resolveAddress(item, items) {
    if(!item) return item;
    item.address = items[item.address] || item.address;
    return item;
}

export function resolveProperty(item, items) {
    if(!item) return item;
    if(typeof item === "string") item = items[item];
    return resolveAddress(item, items);
}