<script>
    import { propertyId, policyId, view, property, properties, policies, policy, units, tenants, params } from './stores';
    import { get } from "lodash-es";
    import PostNotice from "./PostNotice.svelte";
    import Handout from "./Handout.svelte";
    import Instructions from "./Instructions.svelte";

    let docs = "general"
    let pdfUrl = null;
    let generating = null;
    let tenant = null;

    $: general = docs === "general";
    $: tenantHandouts = docs === "tenants";
    $: amenityNotices = docs === "amenities";
    
    $: loaded = $property && $policies && $tenants;

    $: if(loaded && $params.pdf === "true") pdf();

    $: document.documentElement[$view ? "setAttribute" : "removeAttribute"]("data-view", $view);
    $: document.documentElement[$propertyId ? "setAttribute" : "removeAttribute"]("data-property", $propertyId);
    $: document.documentElement[$policyId ? "setAttribute" : "removeAttribute"]("data-policy", $policyId);
    //$: document.documentElement[$permitId ? "setAttribute" : "removeAttribute"]("data-permit", $permitId);

    function pdf(e) {

        if(!!e) e.preventDefault();

        generating = true;

        fetch("https://v2.api2pdf.com/chrome/pdf/html", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "c550eb1b-888b-4377-ac99-096487c3ee13",
            },
            body: JSON.stringify({
                "html": new XMLSerializer().serializeToString(document).replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, ""),
                "inline": false,
                "inlinePdf": false, // should download on nav
                "fileName": `${name} Amenity Notices.pdf`,
                "options": {
                    //"landscape":"true",
                    "width": "8.5in",
                    "height": "11in",
                    "marginTop": "0.75in",
                    "marginBottom": "0.75in",
                    "marginLeft": "0.75in",
                    "marginRight": "0.75in",
                    "printBackground": true,
                }
            }),
        })
        .then(res => res.json() )
        .then(json => {
            generating = false;
            window.location.href = json.FileUrl
        });

        // fetch("https://v2018.api2pdf.com/chrome/html", {
        //     method: "POST",
        //     headers: {
        //         "Accept": "application/json",
        //         "Content-Type": "application/json",
        //         "Authorization": "c550eb1b-888b-4377-ac99-096487c3ee13",
        //     },
        //     body: JSON.stringify({
        //         "html": new XMLSerializer().serializeToString(document).replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, ""),
        //         "inlinePdf": false, // should download on nav
        //         "fileName": "amenity-pass-notices.pdf",
        //         "options": {
        //             marginBottom:0,
        //             marginTop:0,
        //             marginLeft:0,
        //             marginRight:0
        //         }
        //     }),
        // })
        // .then(res => res.json() )
        // .then(json => {
        //     generating = false;
        //     window.location.href = json.pdf
        // });
        
    }

</script>
<form>
    <ul on:change={e => (pdfUrl = null) || (!!e.target.checked && (docs = e.target.value))}>
        <li><input id="docs-general" type="radio" name="docs" checked value="general" disabled={generating} /><label for="docs-general">General</label></li>
        <li><input id="docs-tenants" type="radio" name="docs" value="tenants" disabled={generating} /><label for="docs-tenants">{get($property, "tenants.title", "Unit")} Handouts</label>{#if tenantHandouts}<select value={tenant} on:change={e => (tenant = e.target.value)}>
            <option value="">All {get($property, "tenants.format", "unit")}s</option>
            {#each $tenants as item}
                {#if item && item.authcode}
                    <option value="{item.id}">{item.display}</option>
                {/if}
            {/each}
        </select>{/if}</li>
        <li><input id="docs-amenity" type="radio" name="docs" value="amenities" disabled={generating} /><label for="docs-amenity">Amenity Notices</label></li>
    </ul>
    {#if loaded}
    <button on:click={pdf} disabled={generating}>{generating ? "Generating…" : "Download PDF"}</button>
    {/if}
</form>
{#if $property}
    {#if general}
        <Handout property={$property} />
        <Instructions property={$property} />
    {/if}
    {#if tenantHandouts && $tenants}
        {#each $tenants as item}
            {#if item && item.authcode && (!tenant || item.id === tenant) }
                <Handout property={$property} tenant={item} />
            {/if}
        {/each}
    {/if}
    {#if amenityNotices && $policies}
        <PostNotice property={$property} />
        {#each $policies as policy}
            {#if policy}
            <PostNotice property={$property} policy={policy} />
            {/if}
        {/each}
    {/if}
{/if}