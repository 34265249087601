import router from "page";
import { each, merge } from "lodash-es";
import { params } from "./stores";
import App from "./App.svelte";
import { qs } from "@parkingboss/utils";
import { api } from "./api";

//console.log(api, api.isLoggedIn(), api.user);
if (!api.isLoggedIn()) api.logIn(true);
window.API = api;

const app = window.app = new App({ target: document.body, props: {} });

// set page base path
const prefixPath = "/properties/"
const entryPathName = location.pathname;
const prefixIndex = entryPathName.indexOf(prefixPath);
const base = prefixIndex >= 0 ? entryPathName.substring(0, prefixIndex) : "";

if(!!base) router.base(base);

const bases = document.getElementsByTagName('base');
function setBaseUri(url) {
  if (!bases[0]) {
    document.head.insertAdjacentHTML('afterbegin', `<base>`);
  }
  bases[0].href = `${base || "/"}${!base || base.endsWith("/") ? "" : "/"}${url}`;
}

setBaseUri();

//querystring
router("*", function(ctx, next) {
    ctx.query = qs.parse(ctx.querystring);
    next();
});

function state(ctx, next) {
    params.set(merge({}, ctx.params, ctx.query, qs.parse(location.hash && location.hash.substring(1))));
    if(!!next) next();
}
function unstate(ctx, next) {
    //params.set({});
    next();
}

function end(ctx) {

}

router.exit("*", unstate);


function view(viewname) {
    return function(ctx, next) {
        ctx.params.view = viewname;
        next();
    };
}

router("/properties/:property/*", state);
router("/properties/:property", view("property"), state, end);

router("/p/:permit", view("permit"), state, end);
router("/permits/:permit", view("permit"), state, end);

// policy catch all
router("/properties/:property/amenities/:policy/*", state);
router("/properties/:property/amenities/:policy", view("policy"), state, end);

// property subview
router(`/properties/:property/:view`, state, end);

router.start();